//$FilePathRelativeToProjectRoot$ --out-dir . --source-maps  --presets /usr/lib/node_modules/babel-preset-es2015
//$FileDirRelativeToProjectRoot$/$FileNameWithoutExtension$.js:$FileDirRelativeToProjectRoot$/$FileNameWithoutExtension$.js.map
Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a tabindex="0">Zpět</a></li>';
// Foundation.Drilldown.defaults.autoHeight = true;
// Foundation.Drilldown.defaults.animateHeight = true;
Foundation.Equalizer.defaults.equalizeOnStack = false;

$(document).foundation();

const $window = $(window);
const $body = $('body');
const $heroImage = $('.heroImage');
const $helperColumn = $('.helperColumn');
const resizeBgImage = () => {
    let width = $body.outerWidth() - $helperColumn.position().left;

    if (Foundation.MediaQuery.atLeast('large')) {
        width += 100;
    }

    $heroImage.css('width', width);
};

if ($('body').hasClass('homepage')) {
    let vide_options;
    if (Foundation.MediaQuery.atLeast('medium')) {
        vide_options = {
            poster: '/files/videos/output',
            posterType: 'png',
            mp4: '/files/videos/output',
            webm: '/files/videos/output'
        };
    } else {
        vide_options = {
            poster: '/files/videos/bg',
            posterType: 'png'
        };
    }
    $('.home_header_outer_wrap').vide(
        vide_options
    );
} else {
    console.log($heroImage.length);
    console.log('asd');
    if ($heroImage.length) { // Jen pokud jsem na stránce
        $(window).on('resize', () => {
            resizeBgImage();
        });

        $(document).on('ready', () => {
            resizeBgImage();
        });
    }
}


AOS.init();

lightbox.option(
    {
        'albumLabel': '%1 / %2',
    }
);


/*let $youtubeSlider = $('#youtube_slider');
 $youtubeSlider.slick(
 {
 lazyLoad: 'ondemand',
 dots: true,
 // 		arrows       : false,
 autoplay: true,
 autoplaySpeed: 5000
 }
 );


 $youtubeSlider.find('a')
 .on('mousedown', function (e) {
 $(this).data('moving', false);
 })
 .on('mousemove', function (e) {
 $(this).data('moving', true);
 })
 .on('mouseup', function (e) {
 if (!$(this).data('moving')) {
 let $that = $(this);
 e.preventDefault();
 $.post($that.data('href'), null, function (data) {
 $('#video_modal').html(data).foundation('open');
 });
 }
 });*/

$('[data-ajax-load]').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let href = $this.attr('href');

    $.post(href, null, function (data) {
        $('#universalModal').html(data).foundation('open');
    });
});

$('.formular-view-3 .wrap')
    .each(function () {
        let $that = $(this);
        let label = $that.children('.formInput-Label').remove();
        let placeholder = label.text().trim().toUpperCase();
        $that.find('input, textarea').attr('placeholder', placeholder).attr('required', 'required');
    });


const isInView = ($elem, threshold = 0) => {
    let elementTop = $elem.offset().top;
    let elementBottom = elementTop + $elem.outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return (elementBottom + threshold) > viewportTop && (elementTop - threshold) < viewportBottom;
};


/*$('.main_wrap').each(function () {
    let $this = $(this);

    let url = $this.data('image');

    if (url) {
        $this.prepend($('<div/>', {
            'class': 'bg_pseudo'
        }));
        $this.children('.bg_pseudo').css('background-image', `url(${url})`);
    }
});*/


/*const $menu19 = $('.menu_19');

const $links = $menu19.find('.links');*/
// const $backToTop = $('#backToTop');


/*$backToTop.on('click', e => {
    $('html, body').animate({scrollTop: 0}, '500', 'swing');
});*/

/*
$window.on('scroll', () => {
    if (isInView($menu19)) { // Sticky social menu
        $links.removeClass('unplugged');
    } else {
        $links.addClass('unplugged');
    }


    if ($(window).scrollTop() > 1000) {
        $backToTop.removeClass('hiding');
    } else {
        $backToTop.addClass('hiding');
    }
});*/
